import {
  eachDayOfInterval,
  format,
  getHours,
  parseISO,
  addMinutes,
  getMinutes,
  isSameDay,
  addDays,
  getDay,
} from "date-fns";
import { formatInTimeZone, fromZonedTime, toZonedTime } from "date-fns-tz";
import { useMemo } from "react";

import { AvailabilityItem, TimeOfDay } from "@smart/bridge-types-basic";
import { groupBy, specialChars } from "@smart/itops-utils-basic";

import { maxTimeOfDay, minTimeOfDay } from "../types";

export const displayAppointmentValues = ({
  startTime,
  endTime,
  timezone,
}: {
  startTime: string | undefined;
  endTime: string | undefined;
  timezone: string | undefined;
}) => {
  const timeToDisplay = (timeStr: string) => {
    const dateTime = parseISO(timeStr);
    return timezone
      ? formatInTimeZone(dateTime, timezone, "hh:mm a")
      : format(dateTime, "hh:mm a");
  };

  const dayToDisplay = (timeStr: string) => {
    const dateTime = parseISO(timeStr);
    return timezone
      ? formatInTimeZone(dateTime, timezone, "EEEE do MMM yyyy")
      : format(dateTime, "EEEE do MMM yyyy");
  };

  const hasDate = !!startTime && !!endTime;
  return {
    day: hasDate ? dayToDisplay(startTime) : specialChars.enDash,
    time: hasDate
      ? `${timeToDisplay(startTime)} - ${timeToDisplay(endTime)}`
      : specialChars.enDash,
  };
};

export const formatTimeOfDay = (time: TimeOfDay): string => {
  const hour = time.hour % 12 || 12;
  const minute = time.minute.toString().padStart(2, "0");
  const period = time.hour < 12 ? "AM" : "PM";

  return `${hour}:${minute} ${period}`;
};

export const timeOfDayInMinutes = (time: TimeOfDay): number =>
  time.hour * 60 + time.minute;

export const toTimeOfDay = (minutes: number): TimeOfDay => ({
  hour: Math.floor(minutes / 60),
  minute: minutes % 60,
});

export const buildTimeOfDayRange = (options?: {
  start?: TimeOfDay;
  end?: TimeOfDay;
  duration?: number;
}): TimeOfDay[] => {
  const startInMinutes = timeOfDayInMinutes(options?.start || minTimeOfDay);
  const endInMinutes = timeOfDayInMinutes(options?.end || maxTimeOfDay);
  if (startInMinutes > endInMinutes) return [];

  const range = [];

  const duration = options?.duration || 30;
  let current = startInMinutes;
  while (current <= endInMinutes) {
    range.push(toTimeOfDay(current));
    current += duration;
  }

  return range;
};

export const getDateString = (date: Date) => format(date, "yyyy-MM-dd");

export const convertToZonedTime = (date: Date, timezone?: string | null) =>
  timezone ? toZonedTime(date, timezone) : date;

type CalculateAvailableTimeSlotsOptions = {
  duration: number;
  availability: AvailabilityItem[];
  creationTimezone?: string;
  blocked: {
    fromTime: string;
    toTime: string;
    timezone?: string | null;
  }[];
  viewingTimezone: string;
  minimumNoticeInMinutes?: number;
  bufferTimeInMinutes?: number;
  numberOfDisplayDays: number;
};

const generateAvailableSlotsForDay = ({
  availability,
  dayInCreationTimezone,
  durationInMinute,
  blockedInCreationTimezone,
  bufferTimeInMinutes,
  minimumNoticeInMinutes,
  creationTimezone,
}: {
  availability: AvailabilityItem[];
  dayInCreationTimezone: Date;
  durationInMinute: number;
  blockedInCreationTimezone: {
    fromDateTime: Date;
    toDateTime: Date;
  }[];
  bufferTimeInMinutes?: number;
  minimumNoticeInMinutes?: number;
  creationTimezone?: string;
}) => {
  const dayAvailability = availability.find(
    (a) => a.day === getDay(dayInCreationTimezone) && a.enabled,
  );
  if (!dayAvailability) return [];

  const blockedInCreationTimezoneWithBuffer = blockedInCreationTimezone
    .filter((b) =>
      isSameDay(
        dayInCreationTimezone,
        b.fromDateTime || isSameDay(dayInCreationTimezone, b.toDateTime),
      ),
    )
    .map((b) => ({
      fromDateTime: addMinutes(b.fromDateTime, -(bufferTimeInMinutes || 0)),
      toDateTime: addMinutes(b.toDateTime, bufferTimeInMinutes || 0),
    }));

  const isBlocked = ({
    startTime,
    duration,
    blockedDateTimes,
  }: {
    startTime: Date;
    duration: number;
    blockedDateTimes: { fromDateTime: Date; toDateTime: Date }[];
  }): boolean =>
    blockedDateTimes.length > 0 &&
    blockedDateTimes.some(
      (b) =>
        startTime.getTime() < b.toDateTime.getTime() &&
        addMinutes(startTime, duration).getTime() > b.fromDateTime.getTime(),
    );

  const createZonedTime = (timeOfDay: TimeOfDay) => {
    const copied = new Date(dayInCreationTimezone.getTime());
    copied.setHours(timeOfDay.hour);
    copied.setMinutes(timeOfDay.minute);
    return copied;
  };

  const fromTime = createZonedTime(dayAvailability.fromTime);
  const toTime = createZonedTime(dayAvailability.toTime);
  const earliestAvailable = addMinutes(new Date(), minimumNoticeInMinutes || 0);
  const earliestAvailableInCreationTimezone = convertToZonedTime(
    earliestAvailable,
    creationTimezone,
  );

  const slots = [];
  let currentTime = fromTime;

  while (
    toTime.getTime() - currentTime.getTime() >=
    durationInMinute * 60 * 1000
  ) {
    const nextTime = addMinutes(currentTime, durationInMinute);
    if (
      currentTime.getTime() >= earliestAvailableInCreationTimezone.getTime() &&
      !isBlocked({
        startTime: currentTime,
        duration: durationInMinute,
        blockedDateTimes: blockedInCreationTimezoneWithBuffer,
      })
    ) {
      slots.push(currentTime);
    }
    currentTime = nextTime;
  }

  return slots;
};

const calculateAvailableTimeSlots = (
  options: CalculateAvailableTimeSlotsOptions,
): Record<string, TimeOfDay[]> => {
  const {
    availability,
    viewingTimezone,
    creationTimezone,
    duration,
    blocked,
    bufferTimeInMinutes,
    minimumNoticeInMinutes,
    numberOfDisplayDays,
  } = options;

  // If creationTimezone is not set, it's considered in UTC
  const startDateInCreationTimezone = convertToZonedTime(
    new Date(),
    creationTimezone,
  );

  const blockedInCreationTimezone = blocked.map((b) => {
    const utcFrom = fromZonedTime(b.fromTime, b.timezone || "UTC");
    const utcTo = fromZonedTime(b.toTime, b.timezone || "UTC");

    return {
      fromDateTime: convertToZonedTime(utcFrom, creationTimezone),
      toDateTime: convertToZonedTime(utcTo, creationTimezone),
    };
  });

  const slotsInCreationTimezone = [];
  let currentDate = startDateInCreationTimezone;

  while (slotsInCreationTimezone.length < numberOfDisplayDays) {
    const slots = generateAvailableSlotsForDay({
      availability,
      dayInCreationTimezone: currentDate,
      durationInMinute: duration,
      blockedInCreationTimezone,
      bufferTimeInMinutes,
      minimumNoticeInMinutes,
      creationTimezone,
    });

    if (slots.length) {
      slotsInCreationTimezone.push(slots);
    }
    currentDate = addDays(currentDate, 1);
  }

  const slotsInViewingTimezone = slotsInCreationTimezone
    .flat()
    .map((startTimeInCreationTimezone) => {
      const utcStartTime = creationTimezone
        ? fromZonedTime(startTimeInCreationTimezone, creationTimezone)
        : startTimeInCreationTimezone;
      return convertToZonedTime(utcStartTime, viewingTimezone);
    });
  const groupedSlotsInViewingTimezone = groupBy(
    slotsInViewingTimezone,
    (slot) => getDateString(slot),
  );

  return Object.keys(groupedSlotsInViewingTimezone).reduce(
    (aggr, date) => ({
      ...aggr,
      [date]: groupedSlotsInViewingTimezone[date].map(
        (startTimeInViewingTimezone) => ({
          hour: getHours(startTimeInViewingTimezone),
          minute: getMinutes(startTimeInViewingTimezone),
        }),
      ),
    }),
    {},
  );
};

export const useAvailableSlots = (
  options: CalculateAvailableTimeSlotsOptions & {
    selectedDateInViewingTimezone: string | null | undefined;
    deps: any[];
  },
) => {
  const { selectedDateInViewingTimezone, deps, ...rest } = options;
  const availableSlotsInViewingTimezone = useMemo(
    () => calculateAvailableTimeSlots(rest),
    [...deps],
  );

  const { startDate, toDate, excludingDates } = useMemo(() => {
    /**
     * parseISO function will create the date with local timezone.
     * This means if the date is formatted using the format function
     * from date-fns, the result will always be the same date.
     * E.g, format(parseISO("2025-01-31"), "yyyy-MM-dd") will always be "2025-01-31".
     * If we use the new Date() methed: format(new Date("2025-01-31"), "yyyy-MM-dd"),
     * the result may vary based on your local timezone being behind or ahead of UTC time
     * since new Date("2025-01-31") will create a date object with UTC time 00:00:00 on 2025-01-31.
     */
    const orderedDates = Object.keys(availableSlotsInViewingTimezone)
      .map((dateString) => parseISO(dateString))
      .sort((a, b) => a.getTime() - b.getTime());
    const startDateString = getDateString(orderedDates[0]);
    const toDateString = getDateString(orderedDates[orderedDates.length - 1]);
    const allDatesInRange = eachDayOfInterval({
      start: orderedDates[0],
      end: orderedDates[orderedDates.length - 1],
    });
    const excludingDateStrings = allDatesInRange
      .filter((date) => !orderedDates.some((d) => isSameDay(date, d)))
      .map((date) => getDateString(date));

    return {
      startDate: startDateString,
      toDate: toDateString,
      excludingDates: excludingDateStrings,
    };
  }, [...deps]);

  return {
    startDate,
    toDate,
    excludingDates,
    availableTimeSlotsForSelectedDate: selectedDateInViewingTimezone
      ? availableSlotsInViewingTimezone[
          getDateString(parseISO(selectedDateInViewingTimezone))
        ]
      : undefined,
  };
};
